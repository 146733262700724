import React from "react";
const Footer = () => {
    return (
        <footer className="bg-[#414840]">
            <div className="container px-6 py-8 mx-auto space-y-8 lg:space-y-0 lg:flex lg:justify-between justify-center">
                <div className="justify-center">
                    <div className="flex items-center mx-2" >
                        <img src="/imgs/Wedding-Logo-Reverse.png" alt="Georgia and Oliver's wedding logo" className="w-12 h-12" />

                        <div className="mx-2 text-white justify-center">
                            <h3 className="font-medium tracking-widest uppercase">Georgia and Oliver's wedding</h3>
                            <p className="mt-1 text-xs italic leading-3 tracking-wide capitalize">At <a href="https://kingsfordbarossa.com.au/" target="_blank" rel="noreferrer">Kingsford The Barossa</a></p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row space-x-4 justify-center">
                    <a href="/" className="text-white hover:text-gray-300">Home</a> 
                    <b>|</b> 
                    <a href="/details" className="text-white hover:text-gray-300">Details</a>
                    {/* <b>|</b> */}
                    {/* <a href="/gallery" className="text-white hover:text-gray-300">Gallery</a> <b>|</b> */}
                    {/* <a href="/rsvp" className="text-white hover:text-gray-300">RSVP</a>  */}
                    {/* <b>|</b> */}
                    {/* <a href="/program" className="text-white hover:text-gray-300">Program</a> <b>|</b>
                    <a href="/contact" className="text-white hover:text-gray-300">Contact</a> */}
                </div>
            
            </div>
            
            <p className="w-full py-6 mx-auto text-white text-center bg-[#343D33]">
            &copy; 2022 Oliver Holmes. All rights reserved.
            </p>
        </footer>
    );
};
export default Footer;